/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-01",
    versionChannel: "nightly",
    buildDate: "2024-09-01T00:06:38.962Z",
    commitHash: "ad0d3f8fbf4cba09e66d84d077f6a2df23a409b9",
};
